import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import Vimeo       from '@u-wave/react-vimeo';

import { findT }        from '@interness/web-core/src/components/_helpers';
import Carousel         from '@interness/web-core/src/components/media/Carousel/Carousel';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Link             from '@interness/web-core/src/components/elements/Link/Link';
import PageLayout       from '@interness/theme-default/src/@interness/brands-addon/templates/page/base';


const BrandPageTemplate = props => {
  const page = props.data.directusBrands.brand;
  const translations = findT(page.translations, props.pageContext.lang);
  const typeTranslations = findT(props.data.directusBrandTypes.translations, props.pageContext.lang);
  const headerImage = page.header_images[0].file.localFile.childImageSharp.fluid;
  const seoTitle = `${translations.seo_title ? translations.seo_title : page.display_name} ${typeTranslations.display_name}`;
  return (
    <PageLayout page={page} translations={translations} seoTitle={seoTitle} showCallToAction showSubnavigation
                headerImage={headerImage}
                type={page.type.type}>
      {page.gallery_images.length > 0 &&
        <>
          <div>
            <Carousel>
              {page.gallery_images.map(image => (
                <figure key={image.file.localFile.name}>
                  <Img fluid={image.file.localFile.childImageSharp.fluid} alt={image.file.localFile.name}/>
                  {image.file.description &&
                    <figcaption style={{ backgroundColor: '#fff', paddingTop: '20px' }}
                                dangerouslySetInnerHTML={{ __html: image.file.description }}/>}
                </figure>
              ))}
            </Carousel>
          </div>
          <Spacer/>
        </>}
      {page.youtube_video_id &&
        <>
          <ResponsiveIFrame src={`https://www.youtube-nocookie.com/embed/${page.youtube_video_id}?rel=0&amp;controls=0`}
                            title={`YoutubeVideo`}/>
          <Spacer/>
        </>}
      {page.vimeo_video_id &&
        <>
          <div style={{ position: 'relative', 'width': '100%', paddingTop: '56.25%' }}>
            <Vimeo responsive={true} video={`https://vimeo.com/${page.vimeo_video_id}`} dnt={true}
                   style={{ border: '0', position: 'absolute', height: '100%', width: '100%', top: '0', left: '0' }}
            />
          </div>
          <Spacer/>
        </>}
      <div>
        {translations.description &&
          <>
            <h3>{page.display_name}</h3>
            <div style={{ textAlign: 'justify' }}
                 dangerouslySetInnerHTML={{ __html: translations.description }}/>
          </>
        }
      </div>
      {page.website && <div>
        <Link external to={page.website}>Zur Herstellerwebseite</Link>
      </div>}
    </PageLayout>
  )
};

export default BrandPageTemplate;

export const query = graphql`
    query($slug: String = "/undefined", $type: String = "undefined") {
        directusBrands(brand: {slug: {eq: $slug}, type: {type: {eq: $type}}}) {
            ...BrandsCore
            ...BrandHeaderImages
            ...BrandGalleryImages
        }
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                display_name
                language
            }
        }
    }
`;